import { scanSplitApi as api } from "./apiBase";
const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        devicesControllerCreateFootMeasurements: build.mutation<
            DevicesControllerCreateFootMeasurementsApiResponse,
            DevicesControllerCreateFootMeasurementsApiArg
        >({
            query: (queryArg) => ({
                url: `/scans/${queryArg.clientReference}/foot-measurements`,
                method: "POST",
                body: queryArg.footMeasurements,
            }),
        }),
        measurementsControllerRetrieveScanMeasurements: build.query<
            MeasurementsControllerRetrieveScanMeasurementsApiResponse,
            MeasurementsControllerRetrieveScanMeasurementsApiArg
        >({
            query: (queryArg) => ({
                url: `/scans/${queryArg.scanId}/measurements`,
            }),
        }),
        measurementsControllerRetrieveSessionMeasurements: build.query<
            MeasurementsControllerRetrieveSessionMeasurementsApiResponse,
            MeasurementsControllerRetrieveSessionMeasurementsApiArg
        >({
            query: (queryArg) => ({
                url: `/scans/${queryArg.sessionId}/measurements`,
                params: { impersonating: queryArg.impersonating },
            }),
        }),
        measurementsControllerRetrieveMeasurementsCsv: build.query<
            MeasurementsControllerRetrieveMeasurementsCsvApiResponse,
            MeasurementsControllerRetrieveMeasurementsCsvApiArg
        >({
            query: (queryArg) => ({ url: `/scans/${queryArg.sessionId}/csv` }),
        }),
        measurementsControllerRetrieveAllMeasurements: build.query<
            MeasurementsControllerRetrieveAllMeasurementsApiResponse,
            MeasurementsControllerRetrieveAllMeasurementsApiArg
        >({
            query: (queryArg) => ({
                url: `/scans/${queryArg.scanId}/all-measurements`,
            }),
        }),
        measurementsControllerRetrieveMeasurementAmendments: build.query<
            MeasurementsControllerRetrieveMeasurementAmendmentsApiResponse,
            MeasurementsControllerRetrieveMeasurementAmendmentsApiArg
        >({
            query: (queryArg) => ({
                url: `/scans/${queryArg.scanId}/measurements/${queryArg.measurementsId}/amendments`,
            }),
        }),
        measurementsControllerUpdateScanMeasurement: build.mutation<
            MeasurementsControllerUpdateScanMeasurementApiResponse,
            MeasurementsControllerUpdateScanMeasurementApiArg
        >({
            query: (queryArg) => ({
                url: `/scans/${queryArg.scanId}/measurements/${queryArg.measurementsId}/${queryArg.measurementKey}`,
                method: "PUT",
                body: queryArg.measurementAmendmentDto,
            }),
        }),
        reportsControllerRetrieveScanQualityReport: build.query<
            ReportsControllerRetrieveScanQualityReportApiResponse,
            ReportsControllerRetrieveScanQualityReportApiArg
        >({
            query: (queryArg) => ({
                url: `/reports/scan-quality-report`,
                params: {
                    From: queryArg["from"],
                    To: queryArg.to,
                    MerchantId: queryArg.merchantId,
                },
            }),
        }),
        reportsControllerRetrieveMeasurementQualityReport: build.query<
            ReportsControllerRetrieveMeasurementQualityReportApiResponse,
            ReportsControllerRetrieveMeasurementQualityReportApiArg
        >({
            query: (queryArg) => ({
                url: `/reports/measurement-quality-report`,
                params: {
                    From: queryArg["from"],
                    To: queryArg.to,
                    MerchantId: queryArg.merchantId,
                },
            }),
        }),
        reportsControllerRetrieveSizeMatchAccuracyReport: build.query<
            ReportsControllerRetrieveSizeMatchAccuracyReportApiResponse,
            ReportsControllerRetrieveSizeMatchAccuracyReportApiArg
        >({
            query: (queryArg) => ({
                url: `/reports/sizematch-accuracy-report`,
                params: {
                    MerchantId: queryArg.merchantId,
                    ProductCode: queryArg.productCode,
                    From: queryArg["from"],
                    To: queryArg.to,
                },
            }),
        }),
        reportsControllerRetrieveScannerUsageReport: build.query<
            ReportsControllerRetrieveScannerUsageReportApiResponse,
            ReportsControllerRetrieveScannerUsageReportApiArg
        >({
            query: (queryArg) => ({
                url: `/reports/scanner-usage-report`,
                params: {
                    Month: queryArg.month,
                    MerchantId: queryArg.merchantId,
                },
            }),
        }),
        scanControllerRetrieveScans: build.query<
            ScanControllerRetrieveScansApiResponse,
            ScanControllerRetrieveScansApiArg
        >({
            query: (queryArg) => ({
                url: `/scans`,
                params: {
                    MerchantId: queryArg.merchantId,
                    Page: queryArg.page,
                    PageSize: queryArg.pageSize,
                    DeviceId: queryArg.deviceId,
                },
            }),
        }),
        scanControllerRetrieveScan: build.query<
            ScanControllerRetrieveScanApiResponse,
            ScanControllerRetrieveScanApiArg
        >({
            query: (queryArg) => ({ url: `/scans/${queryArg.scanId}` }),
        }),
        scanControllerRetrieveWellnessMeasurements: build.query<
            ScanControllerRetrieveWellnessMeasurementsApiResponse,
            ScanControllerRetrieveWellnessMeasurementsApiArg
        >({
            query: (queryArg) => ({
                url: `/scans/${queryArg.scanId}/wellness`,
            }),
        }),
        scanControllerSubmitQualityReport: build.mutation<
            ScanControllerSubmitQualityReportApiResponse,
            ScanControllerSubmitQualityReportApiArg
        >({
            query: (queryArg) => ({
                url: `/scans/${queryArg.scanId}/quality-report`,
                method: "PUT",
                body: queryArg.scanQualityReportDto,
            }),
        }),
        scanControllerRetrieveDownloadUrl: build.query<
            ScanControllerRetrieveDownloadUrlApiResponse,
            ScanControllerRetrieveDownloadUrlApiArg
        >({
            query: (queryArg) => ({
                url: `/scans/${queryArg.scanId}/raw-download`,
            }),
        }),
        scanControllerRequestIntegration: build.mutation<
            ScanControllerRequestIntegrationApiResponse,
            ScanControllerRequestIntegrationApiArg
        >({
            query: (queryArg) => ({
                url: `/scans/${queryArg.scanId}/integrations`,
                method: "POST",
                params: { integrationDetails: queryArg.integrationDetails },
            }),
        }),
        scanControllerRetrieveScanSessionTitle: build.query<
            ScanControllerRetrieveScanSessionTitleApiResponse,
            ScanControllerRetrieveScanSessionTitleApiArg
        >({
            query: (queryArg) => ({ url: `/scans/${queryArg.scanId}/title` }),
        }),
        sessionsControllerRetrieveSessions: build.query<
            SessionsControllerRetrieveSessionsApiResponse,
            SessionsControllerRetrieveSessionsApiArg
        >({
            query: (queryArg) => ({
                url: `/sessions`,
                params: {
                    MerchantId: queryArg.merchantId,
                    Page: queryArg.page,
                    DeviceId: queryArg.deviceId,
                    Title: queryArg.title,
                    ScanId: queryArg.scanId,
                    From: queryArg["from"],
                    To: queryArg.to,
                    QaPending: queryArg.qaPending,
                },
            }),
        }),
        sessionsControllerRetrieveSession: build.query<
            SessionsControllerRetrieveSessionApiResponse,
            SessionsControllerRetrieveSessionApiArg
        >({
            query: (queryArg) => ({ url: `/sessions/${queryArg.id}` }),
        }),
        sessionsControllerRetrieveSessionStatus: build.query<
            SessionsControllerRetrieveSessionStatusApiResponse,
            SessionsControllerRetrieveSessionStatusApiArg
        >({
            query: (queryArg) => ({ url: `/sessions/${queryArg.id}/status` }),
        }),
        sessionsControllerRetrieveDiagnosticsUrl: build.query<
            SessionsControllerRetrieveDiagnosticsUrlApiResponse,
            SessionsControllerRetrieveDiagnosticsUrlApiArg
        >({
            query: (queryArg) => ({
                url: `/sessions/${queryArg.id}/diagnostics`,
            }),
        }),
        sessionsControllerSubscribeSession: build.mutation<
            SessionsControllerSubscribeSessionApiResponse,
            SessionsControllerSubscribeSessionApiArg
        >({
            query: (queryArg) => ({
                url: `/sessions/${queryArg.clientReference}/subscriptions`,
                method: "POST",
            }),
        }),
        sessionsControllerDeleteSession: build.mutation<
            SessionsControllerDeleteSessionApiResponse,
            SessionsControllerDeleteSessionApiArg
        >({
            query: (queryArg) => ({
                url: `/sessions/${queryArg.merchantId}/${queryArg.sessionId}`,
                method: "DELETE",
            }),
        }),
        sizeMatchControllerRetrieveScanSizeMatchRecommendations: build.query<
            SizeMatchControllerRetrieveScanSizeMatchRecommendationsApiResponse,
            SizeMatchControllerRetrieveScanSizeMatchRecommendationsApiArg
        >({
            query: (queryArg) => ({
                url: `/size-match/${queryArg.merchantId}/scans/${queryArg.scanId}`,
                params: {
                    ProductCode: queryArg.productCode,
                    Page: queryArg.page,
                    PageSize: queryArg.pageSize,
                },
            }),
        }),
        sizeMatchControllerRetrieveSessionSizeMatchRecommendations: build.query<
            SizeMatchControllerRetrieveSessionSizeMatchRecommendationsApiResponse,
            SizeMatchControllerRetrieveSessionSizeMatchRecommendationsApiArg
        >({
            query: (queryArg) => ({
                url: `/size-match/${queryArg.merchantId}/scans/${queryArg.sessionId}`,
                params: {
                    ProductCode: queryArg.productCode,
                    Page: queryArg.page,
                    PageSize: queryArg.pageSize,
                },
            }),
        }),
        sizeMatchControllerRetrieveSizeMatchRecommendationsCsv: build.query<
            SizeMatchControllerRetrieveSizeMatchRecommendationsCsvApiResponse,
            SizeMatchControllerRetrieveSizeMatchRecommendationsCsvApiArg
        >({
            query: (queryArg) => ({
                url: `/size-match/${queryArg.merchantId}/scans/${queryArg.sessionId}/csv`,
            }),
        }),
        sizeMatchControllerRetrieveProductSizeMatchRecommendations: build.query<
            SizeMatchControllerRetrieveProductSizeMatchRecommendationsApiResponse,
            SizeMatchControllerRetrieveProductSizeMatchRecommendationsApiArg
        >({
            query: (queryArg) => ({
                url: `/size-match/${queryArg.merchantId}/products/${queryArg.productCode}`,
                params: { from: queryArg["from"] },
            }),
        }),
        sizeMatchControllerDeleteSizeMatchResult: build.mutation<
            SizeMatchControllerDeleteSizeMatchResultApiResponse,
            SizeMatchControllerDeleteSizeMatchResultApiArg
        >({
            query: (queryArg) => ({
                url: `/size-match/${queryArg.merchantId}/scans/${queryArg.scanId}/${queryArg.resultId}`,
                method: "DELETE",
            }),
        }),
        sizeMatchControllerAddOrUpdateSizeMatchReport: build.mutation<
            SizeMatchControllerAddOrUpdateSizeMatchReportApiResponse,
            SizeMatchControllerAddOrUpdateSizeMatchReportApiArg
        >({
            query: (queryArg) => ({
                url: `/size-match/${queryArg.merchantId}/scans/${queryArg.scanId}/${queryArg.resultId}/report`,
                method: "PUT",
                body: queryArg.sizeMatchReportDto,
            }),
        }),
        sizeMatchControllerRetrieveSizeMatchRecommendations: build.query<
            SizeMatchControllerRetrieveSizeMatchRecommendationsApiResponse,
            SizeMatchControllerRetrieveSizeMatchRecommendationsApiArg
        >({
            query: (queryArg) => ({
                url: `/size-match`,
                params: {
                    MerchantId: queryArg.merchantId,
                    ScanId: queryArg.scanId,
                    ScanTitle: queryArg.scanTitle,
                    ProductCode: queryArg.productCode,
                    GarmentType: queryArg.garmentType,
                    From: queryArg["from"],
                    To: queryArg.to,
                    Gender: queryArg.gender,
                    CategoryTags: queryArg.categoryTags,
                    SessionId: queryArg.sessionId,
                    Page: queryArg.page,
                    PageSize: queryArg.pageSize,
                },
            }),
        }),
        sizeMatchControllerRetrieveSizeMatchRecommendationsBulkCsv: build.query<
            SizeMatchControllerRetrieveSizeMatchRecommendationsBulkCsvApiResponse,
            SizeMatchControllerRetrieveSizeMatchRecommendationsBulkCsvApiArg
        >({
            query: (queryArg) => ({
                url: `/size-match/bulk-csv`,
                params: {
                    MerchantId: queryArg.merchantId,
                    ScanTitle: queryArg.scanTitle,
                    ProductCode: queryArg.productCode,
                    GarmentType: queryArg.garmentType,
                    From: queryArg["from"],
                    To: queryArg.to,
                    Gender: queryArg.gender,
                    CategoryTags: queryArg.categoryTags,
                },
            }),
        }),
        sizeMatchControllerRetrieveSizeMatchRecommendationsStatistics:
            build.query<
                SizeMatchControllerRetrieveSizeMatchRecommendationsStatisticsApiResponse,
                SizeMatchControllerRetrieveSizeMatchRecommendationsStatisticsApiArg
            >({
                query: (queryArg) => ({
                    url: `/size-match/statistics`,
                    params: {
                        MerchantId: queryArg.merchantId,
                        SizeMatchId: queryArg.sizeMatchId,
                        ProductCode: queryArg.productCode,
                        Recommendation: queryArg.recommendation,
                        Dimension: queryArg.dimension,
                    },
                }),
            }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as scanApi };
export type DevicesControllerCreateFootMeasurementsApiResponse = unknown;
export type DevicesControllerCreateFootMeasurementsApiArg = {
    /** The scan session identifier */
    clientReference: string;
    /** Foot measurements */
    footMeasurements: FootMeasurements;
};
export type MeasurementsControllerRetrieveScanMeasurementsApiResponse =
    /** status 200 OK */ MeasurementCollectionDto;
export type MeasurementsControllerRetrieveScanMeasurementsApiArg = {
    scanId: string;
};
export type MeasurementsControllerRetrieveSessionMeasurementsApiResponse =
    /** status 200 OK */ MeasurementCollectionDto[];
export type MeasurementsControllerRetrieveSessionMeasurementsApiArg = {
    sessionId: number;
    impersonating?: boolean;
};
export type MeasurementsControllerRetrieveMeasurementsCsvApiResponse =
    /** status 200 OK */ string;
export type MeasurementsControllerRetrieveMeasurementsCsvApiArg = {
    sessionId: number;
};
export type MeasurementsControllerRetrieveAllMeasurementsApiResponse =
    /** status 200 OK */ MeasurementCollectionDto;
export type MeasurementsControllerRetrieveAllMeasurementsApiArg = {
    scanId: string;
};
export type MeasurementsControllerRetrieveMeasurementAmendmentsApiResponse =
    /** status 200 OK */ MeasurementAmendmentDto[];
export type MeasurementsControllerRetrieveMeasurementAmendmentsApiArg = {
    scanId: string;
    measurementsId: string;
};
export type MeasurementsControllerUpdateScanMeasurementApiResponse =
    /** status 200 OK */ MeasurementDto;
export type MeasurementsControllerUpdateScanMeasurementApiArg = {
    scanId: string;
    measurementsId: string;
    measurementKey: string;
    measurementAmendmentDto: MeasurementAmendmentDto;
};
export type ReportsControllerRetrieveScanQualityReportApiResponse = unknown;
export type ReportsControllerRetrieveScanQualityReportApiArg = {
    from?: string;
    to?: string;
    merchantId?: number;
};
export type ReportsControllerRetrieveMeasurementQualityReportApiResponse =
    unknown;
export type ReportsControllerRetrieveMeasurementQualityReportApiArg = {
    from?: string;
    to?: string;
    merchantId?: number;
};
export type ReportsControllerRetrieveSizeMatchAccuracyReportApiResponse =
    unknown;
export type ReportsControllerRetrieveSizeMatchAccuracyReportApiArg = {
    merchantId?: number;
    productCode?: string;
    from?: string;
    to?: string;
};
export type ReportsControllerRetrieveScannerUsageReportApiResponse =
    /** status 200 OK */ ScannerUsageReportWrapperDto;
export type ReportsControllerRetrieveScannerUsageReportApiArg = {
    month?: string;
    merchantId?: number;
};
export type ScanControllerRetrieveScansApiResponse =
    /** status 200 OK */ ScanSummaryPagedList;
export type ScanControllerRetrieveScansApiArg = {
    merchantId?: number;
    page?: number;
    pageSize?: number;
    deviceId?: string;
};
export type ScanControllerRetrieveScanApiResponse =
    /** status 200 OK */ ScanDetailsDto;
export type ScanControllerRetrieveScanApiArg = {
    scanId: string;
};
export type ScanControllerRetrieveWellnessMeasurementsApiResponse =
    /** status 200 OK */ WellnessScanDto;
export type ScanControllerRetrieveWellnessMeasurementsApiArg = {
    scanId: string;
};
export type ScanControllerSubmitQualityReportApiResponse = unknown;
export type ScanControllerSubmitQualityReportApiArg = {
    scanId: string;
    scanQualityReportDto: ScanQualityReportDto;
};
export type ScanControllerRetrieveDownloadUrlApiResponse =
    /** status 200 OK */ DownloadUrl;
export type ScanControllerRetrieveDownloadUrlApiArg = {
    scanId: string;
};
export type ScanControllerRequestIntegrationApiResponse = unknown;
export type ScanControllerRequestIntegrationApiArg = {
    scanId: string;
    integrationDetails?: string;
};
export type ScanControllerRetrieveScanSessionTitleApiResponse =
    /** status 200 OK */ ScanSessionTitleDto;
export type ScanControllerRetrieveScanSessionTitleApiArg = {
    scanId: string;
};
export type SessionsControllerRetrieveSessionsApiResponse =
    /** status 200 OK */ SessionSummaryDtoPagedList;
export type SessionsControllerRetrieveSessionsApiArg = {
    merchantId?: number;
    page?: number;
    deviceId?: string;
    title?: string;
    scanId?: string;
    from?: string;
    to?: string;
    qaPending?: boolean;
};
export type SessionsControllerRetrieveSessionApiResponse =
    /** status 200 OK */ SessionDto;
export type SessionsControllerRetrieveSessionApiArg = {
    id: number;
};
export type SessionsControllerRetrieveSessionStatusApiResponse =
    /** status 200 OK */ SessionStatusResultDto;
export type SessionsControllerRetrieveSessionStatusApiArg = {
    id: number;
};
export type SessionsControllerRetrieveDiagnosticsUrlApiResponse =
    /** status 200 OK */ DownloadUrl;
export type SessionsControllerRetrieveDiagnosticsUrlApiArg = {
    id: number;
};
export type SessionsControllerSubscribeSessionApiResponse =
    /** status 200 OK */ SubscribeSessionDto;
export type SessionsControllerSubscribeSessionApiArg = {
    clientReference: string;
};
export type SessionsControllerDeleteSessionApiResponse = unknown;
export type SessionsControllerDeleteSessionApiArg = {
    merchantId: number;
    sessionId: number;
};
export type SizeMatchControllerRetrieveScanSizeMatchRecommendationsApiResponse =
    /** status 200 OK */ SizeMatchRecommendationDtoPagedList;
export type SizeMatchControllerRetrieveScanSizeMatchRecommendationsApiArg = {
    merchantId: number;
    scanId: string;
    productCode?: string;
    page?: number;
    pageSize?: number;
};
export type SizeMatchControllerRetrieveSessionSizeMatchRecommendationsApiResponse =
    /** status 200 OK */ SizeMatchRecommendationDtoPagedList;
export type SizeMatchControllerRetrieveSessionSizeMatchRecommendationsApiArg = {
    merchantId: number;
    sessionId: number;
    productCode?: string;
    page?: number;
    pageSize?: number;
};
export type SizeMatchControllerRetrieveSizeMatchRecommendationsCsvApiResponse =
    /** status 200 OK */ string;
export type SizeMatchControllerRetrieveSizeMatchRecommendationsCsvApiArg = {
    merchantId: number;
    sessionId: number;
};
export type SizeMatchControllerRetrieveProductSizeMatchRecommendationsApiResponse =
    /** status 200 OK */ SizeMatchRecommendationDtoPagedList;
export type SizeMatchControllerRetrieveProductSizeMatchRecommendationsApiArg = {
    merchantId: number;
    productCode: string;
    from?: string;
};
export type SizeMatchControllerDeleteSizeMatchResultApiResponse =
    /** status 200 OK */ SizeMatchReportDto;
export type SizeMatchControllerDeleteSizeMatchResultApiArg = {
    merchantId: number;
    scanId: string;
    resultId: string;
};
export type SizeMatchControllerAddOrUpdateSizeMatchReportApiResponse =
    /** status 200 OK */ SizeMatchReportDto;
export type SizeMatchControllerAddOrUpdateSizeMatchReportApiArg = {
    merchantId: number;
    scanId: string;
    resultId: string;
    sizeMatchReportDto: SizeMatchReportDto;
};
export type SizeMatchControllerRetrieveSizeMatchRecommendationsApiResponse =
    /** status 200 OK */ SizeMatchRecommendationDtoPagedList;
export type SizeMatchControllerRetrieveSizeMatchRecommendationsApiArg = {
    merchantId?: number;
    scanId?: string;
    scanTitle?: string;
    productCode?: string;
    garmentType?: string;
    from?: string;
    to?: string;
    gender?: string;
    categoryTags?: string;
    sessionId?: number;
    page?: number;
    pageSize?: number;
};
export type SizeMatchControllerRetrieveSizeMatchRecommendationsBulkCsvApiResponse =
    /** status 200 OK */ string;
export type SizeMatchControllerRetrieveSizeMatchRecommendationsBulkCsvApiArg = {
    merchantId?: number;
    scanTitle?: string;
    productCode?: string;
    garmentType?: string;
    from?: string;
    to?: string;
    gender?: string;
    categoryTags?: string;
};
export type SizeMatchControllerRetrieveSizeMatchRecommendationsStatisticsApiResponse =
    /** status 200 OK */ SizeMatchRecommendationsStatisticsDto;
export type SizeMatchControllerRetrieveSizeMatchRecommendationsStatisticsApiArg =
    {
        merchantId?: number;
        sizeMatchId?: string;
        productCode?: string;
        recommendation?: string;
        dimension?: string;
    };
export type FootMeasurements = {
    measurementMethod?: string | null;
    leftFootLength?: number;
    leftFootStickWidth?: number;
    leftFootTreadWidth?: number;
    leftFootJointGirth?: number;
    rightFootLength?: number;
    rightFootStickWidth?: number;
    rightFootTreadWidth?: number;
    rightFootJointGirth?: number;
};
export type ScanType =
    | "BodyScan"
    | "FootScan"
    | "WellnessScan"
    | "HandScan"
    | "HeadScan"
    | "HealthScan";
export type MeasurementDto = {
    key?: string | null;
    value?: number;
};
export type MeasurementCollectionDto = {
    measurementsId?: string;
    scanType?: ScanType;
    measurements?: MeasurementDto[] | null;
};
export type MeasurementAmendmentDto = {
    measurementKey?: string | null;
    amendedValue?: number;
    description?: string | null;
    requiresAttention?: boolean;
    measurementsId?: string;
    scanId?: string;
};
export type ScannerUsageReportRowDto = {
    deviceId?: string | null;
    scanCount?: number;
};
export type ScannerUsageReportWrapperDto = {
    title?: string | null;
    rows?: ScannerUsageReportRowDto[] | null;
};
export type JsonNodeOptions = {
    propertyNameCaseInsensitive?: boolean;
};
export type JsonNode = {
    options?: JsonNodeOptions;
    parent?: JsonNode;
    root?: JsonNode;
};
export type ScanStatus =
    | "Receiving"
    | "Processing"
    | "Pending"
    | "Available"
    | "Failed"
    | "Deleted";
export type ScanSummary = {
    id?: string;
    deviceId?: string | null;
    merchantId?: number;
    sessionMetadata?: {
        [key: string]: JsonNode;
    } | null;
    scanStatus?: ScanStatus;
    scanType?: ScanType;
    created?: string;
};
export type ScanSummaryPagedList = {
    items?: ScanSummary[] | null;
    pageNumber?: number;
    totalPages?: number;
    totalCount?: number;
};
export type QualityReportOption =
    | "WearingUpper"
    | "WearingLower"
    | "ScanHoles"
    | "Warping"
    | "HairUntied"
    | "Other"
    | "NoIssues"
    | "WearingLoose";
export type ScanQualityReportDto = {
    options?: QualityReportOption[] | null;
    comments?: string | null;
    qualityCheckComplete?: boolean;
};
export type ScanDetailsDto = {
    id?: string;
    deviceId?: string | null;
    merchantId?: number;
    sessionMetadata?: {
        [key: string]: string;
    } | null;
    scanStatus?: ScanStatus;
    scanType?: ScanType;
    qualityReport?: ScanQualityReportDto;
    qaComplete?: boolean;
    created?: string;
    frontImageUrl?: string | null;
    sideImageUrl?: string | null;
    frontProfileImageUrl?: string | null;
    sideProfileImageUrl?: string | null;
    previewUrl?: string | null;
    hasMeasurements?: boolean;
};
export type Gender = "Male" | "Female" | "Other";
export type WellnessScanDto = {
    gender?: Gender;
    height?: number;
    weight?: number;
    heartRate?: number;
    breathing?: number;
    systolicBP?: number;
    diastolicBP?: number;
    heartRateVariability?: number;
    irregularHeartBeats?: number;
    cardiacWorkload?: number;
    vascularCapacity?: number;
    bmi?: number;
    waistToHeight?: number;
    bodyShape?: number;
    facialAge?: number;
    cardioDiseaseRisk?: number;
    heartAttackRisk?: number;
    strokeRisk?: number;
    hyperTensionRisk?: number;
    diabetesRisk?: number;
    hypercholesterolemiaRisk?: number;
    hypertriglyceridemiaRisk?: number;
    hemoglobinA1cRisk?: number;
    fastingBCRisk?: number;
    vitalScore?: number;
    physicalScore?: number;
    physiologicalScore?: number;
    mentalStressIndex?: number;
    mentalScore?: number;
    risksScore?: number;
    wellnessScore?: number;
    snr?: number;
};
export type DownloadUrl = {
    url?: string | null;
};
export type ScanSessionTitleDto = {
    title?: string | null;
};
export type ActivityStatus = "Busy" | "Ready";
export type SessionSummaryDto = {
    id?: number;
    title?: string | null;
    clientReference?: string | null;
    deviceId?: string | null;
    merchantId?: number;
    activityStatus?: ActivityStatus;
    scanTypes?: ScanType[] | null;
    created?: string;
};
export type SessionSummaryDtoPagedList = {
    items?: SessionSummaryDto[] | null;
    pageNumber?: number;
    totalPages?: number;
    totalCount?: number;
};
export type IngressStatus = "Processing" | "Complete";
export type SessionScanDto = {
    id?: string;
    scanType?: ScanType;
    scanStatus?: ScanStatus;
    merchantId?: number;
};
export type BoddRegion = "Unknown" | "AU" | "US" | "USWEST";
export type SessionDto = {
    assetUrls?: {
        [key: string]: string;
    } | null;
    id?: number;
    title?: string | null;
    clientReference?: string | null;
    deviceId?: string | null;
    appVersion?: string | null;
    fitPreference?: string | null;
    merchantId?: number;
    activityStatus?: ActivityStatus;
    ingressStatus?: IngressStatus;
    scans?: SessionScanDto[] | null;
    qaCompleted?: boolean;
    qaFeedback?: QualityReportOption[] | null;
    region?: BoddRegion;
    created?: string;
};
export type ScanStatusItemDto = {
    scanId?: string;
    type?: ScanType;
    status?: ScanStatus;
};
export type SessionStatusResultDto = {
    activityStatus?: ActivityStatus;
    ingressStatus?: IngressStatus;
    scanState?: ScanStatusItemDto[] | null;
    qaFeedback?: QualityReportOption[] | null;
};
export type SubscribeSessionDto = {
    region?: string | null;
    sessionId?: number;
};
export type AlterationDto = {
    dimension?: string | null;
    amount?: number;
    target?: number;
};
export type FitMetadataItem = {
    bodyMeasurement?: number;
    distanceToThreshold?: number;
    proximityToThreshold?: number;
};
export type SizeMatchReportType = "DidNotFit" | "Preference";
export type SizeMatchReportDto = {
    sizeMatchResultId?: string;
    description?: string | null;
    dimensionFitMetadata?: {
        [key: string]: FitMetadataItem;
    } | null;
    sizeTaken?: string | null;
    reportType?: SizeMatchReportType;
};
export type SizeMatchRecommendationDto = {
    id?: string;
    scanId?: string;
    merchantId?: number;
    gender?: string | null;
    productCode?: string | null;
    productName?: string | null;
    fit?: string | null;
    recommendation?: string | null;
    alterations?: AlterationDto[] | null;
    report?: SizeMatchReportDto;
    fitMetadata?: {
        [key: string]: FitMetadataItem;
    } | null;
    created?: string;
    scanTitle?: string | null;
    productCategoryTags?: string[] | null;
    garmentType?: string | null;
    scanSessionId?: number;
    sizeCode?: string | null;
};
export type SizeMatchRecommendationDtoPagedList = {
    items?: SizeMatchRecommendationDto[] | null;
    pageNumber?: number;
    totalPages?: number;
    totalCount?: number;
};
export type SizeMatchRecommendationsStatisticsDto = {
    min?: number | null;
    max?: number | null;
    average?: number | null;
};
export const {
    useDevicesControllerCreateFootMeasurementsMutation,
    useMeasurementsControllerRetrieveScanMeasurementsQuery,
    useMeasurementsControllerRetrieveSessionMeasurementsQuery,
    useMeasurementsControllerRetrieveMeasurementsCsvQuery,
    useMeasurementsControllerRetrieveAllMeasurementsQuery,
    useMeasurementsControllerRetrieveMeasurementAmendmentsQuery,
    useMeasurementsControllerUpdateScanMeasurementMutation,
    useReportsControllerRetrieveScanQualityReportQuery,
    useReportsControllerRetrieveMeasurementQualityReportQuery,
    useReportsControllerRetrieveSizeMatchAccuracyReportQuery,
    useReportsControllerRetrieveScannerUsageReportQuery,
    useScanControllerRetrieveScansQuery,
    useScanControllerRetrieveScanQuery,
    useScanControllerRetrieveWellnessMeasurementsQuery,
    useScanControllerSubmitQualityReportMutation,
    useScanControllerRetrieveDownloadUrlQuery,
    useScanControllerRequestIntegrationMutation,
    useScanControllerRetrieveScanSessionTitleQuery,
    useSessionsControllerRetrieveSessionsQuery,
    useSessionsControllerRetrieveSessionQuery,
    useSessionsControllerRetrieveSessionStatusQuery,
    useSessionsControllerRetrieveDiagnosticsUrlQuery,
    useSessionsControllerSubscribeSessionMutation,
    useSessionsControllerDeleteSessionMutation,
    useSizeMatchControllerRetrieveScanSizeMatchRecommendationsQuery,
    useSizeMatchControllerRetrieveSessionSizeMatchRecommendationsQuery,
    useSizeMatchControllerRetrieveSizeMatchRecommendationsCsvQuery,
    useSizeMatchControllerRetrieveProductSizeMatchRecommendationsQuery,
    useSizeMatchControllerDeleteSizeMatchResultMutation,
    useSizeMatchControllerAddOrUpdateSizeMatchReportMutation,
    useSizeMatchControllerRetrieveSizeMatchRecommendationsQuery,
    useSizeMatchControllerRetrieveSizeMatchRecommendationsBulkCsvQuery,
    useSizeMatchControllerRetrieveSizeMatchRecommendationsStatisticsQuery,
} = injectedRtkApi;
